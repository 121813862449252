(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);

function onConsent(preference) {
    console.log('onConsent', preference);
    eraseCookie('cookie_law_accepted');
    eraseCookie('cookie_law_accepted_1');
    eraseCookie('cookie_law_accepted_2');
    eraseCookie('cookie_law_accepted_3');
    eraseCookie('cookie_law_accepted_4');
    eraseCookie('cookie_law_accepted_5');

    setTimeout(function () {
        if (preference.consent) {
            dataLayer.push({'event': 'cookie_law_accepted'});
            setCookie('cookie_law_accepted', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_1'});
            setCookie('cookie_law_accepted_1', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_2'});
            setCookie('cookie_law_accepted_2', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_3'});
            setCookie('cookie_law_accepted_3', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_4'});
            setCookie('cookie_law_accepted_4', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_5'});
            setCookie('cookie_law_accepted_5', 1, 366);
        } else if (preference.purposes) {
            if (preference.purposes[1]) {
                dataLayer.push({'event': 'cookie_law_accepted_1'});
                dataLayer.push({'cookie_law_var_1': 'accepted'});
                setCookie('cookie_law_accepted_1', 1, 366);
            }
            if (preference.purposes[2]) {
                dataLayer.push({'event': 'cookie_law_accepted_2'});
                dataLayer.push({'cookie_law_var_2': 'accepted'});
                setCookie('cookie_law_accepted_2', 1, 366);
            } else {
                dataLayer.push({'cookie_law_var_2': ''});
            }
            if (preference.purposes[3]) {
                dataLayer.push({'event': 'cookie_law_accepted_3'});
                dataLayer.push({'cookie_law_var_3': 'accepted'});
                setCookie('cookie_law_accepted_3', 1, 366);
            } else {
                dataLayer.push({'cookie_law_var_3': ''});
            }
            if (preference.purposes[4]) {
                dataLayer.push({'event': 'cookie_law_accepted_4'});
                dataLayer.push({'cookie_law_var_4': 'accepted'});
                setCookie('cookie_law_accepted_4', 1, 366);
            } else {
                dataLayer.push({'cookie_law_var_4': ''});
            }
            if (preference.purposes[5]) {
                dataLayer.push({'event': 'cookie_law_accepted_5'});
                dataLayer.push({'cookie_law_var_5': 'accepted'});
                setCookie('cookie_law_accepted_5', 1, 366);
            } else {
                dataLayer.push({'cookie_law_var_5': ''});
            }
        }
    }, 500);
}

var _iub = _iub || [];
_iub.csConfiguration = {
    "whitelabel": false,
    "lang": locale,
    "floatingPreferencesButtonDisplay": "bottom-left",
    "siteId": 2748593,
    "consentOnContinuedBrowsing": false,
    "perPurposeConsent": true,
    "cookiePolicyId": 94128164,
    "callback": {
        "onPreferenceFirstExpressed": function(event) {
            onConsent(event);
        }
    },
    "banner": {
        "acceptButtonDisplay": true,
        "acceptButtonColor":"#000000",
        "rejectButtonDisplay":true,
        "rejectButtonColor":"#4c4c4c",
        "customizeButtonDisplay": true,
        "customizeButtonColor": "#4c4c4c",
        "position": "float-center",
        "backgroundColor": "white",
        "textColor": "black"
    },
};