function setCookie(name, value, days) {
  /**
   * Set Cookie
   *
   * @param name
   * @param value
   * @param days
   */
  var $name = name,
    $value = value,
    $days = days,
    $expires = '';

  if ($days) {
    var $date = new Date();

    $date.setTime($date.getTime() + ($days * 24 * 60 * 60 * 1000));
    $expires = '; expires=' + $date.toUTCString();
  }

  document.cookie = $name + '=' + ($value || '')  + $expires + '; path=/';
}

function getCookie(name) {
  /**
   * Get Cookie
   *
   * @param name
   * @returns {*}
   */
  var $name = name,
    $nameEQ = $name + '=',
    $ca = document.cookie.split(';');

  for (var $i=0; $i < $ca.length; $i++) {
    var $c = $ca[$i];

    while ($c.charAt(0)==' ') $c = $c.substring(1, $c.length);

    if ($c.indexOf($nameEQ) == 0) {
      return $c.substring($nameEQ.length, $c.length);
    }
  }

  return null;
}

function eraseCookie(name) {
    self.setCookie(name,"",-1);
}